import { graphql, useStaticQuery } from 'gatsby';

const useProductCardQuery = () => {
    const { application } = useStaticQuery(graphql`
        {
            application {
                data {
                    config {
                        productCardConfig {
                            infoLabel
                            infoParagraph {
                                ...Paragraph
                            }
                        }
                    }
                }
            }
        }
    `);

    const { infoLabel, infoParagraph } = application.data.config.productCardConfig || {};

    return {
        infoLabel,
        infoParagraph: infoParagraph?.[0]?.richText,
    };
};

export default useProductCardQuery;
