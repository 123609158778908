import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import fm from 'format-message';
import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import useProductCardQuery from 'queries/useProductCardQuery';
import Price from '@activebrands/core-web/components/Price';
import media from '@activebrands/core-web/config/media';
import useEventListener from '@activebrands/core-web/hooks/useEventListener';
import useNotifications from '@activebrands/core-web/hooks/useNotifications';
import Events from '@activebrands/core-web/libs/Events';
import { SearchEvents } from '@activebrands/core-web/libs/algolia/searchInsights';
import overlay from '@activebrands/core-web/libs/overlay';
import RichText from '@activebrands/core-web/libs/storyblok/RichText';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import { addBasketItem } from '@activebrands/core-web/state/basket/actions';
import { useTypedDispatch } from '@activebrands/core-web/state/store';
import { logger } from '@activebrands/core-web/utils/logger';
import ArrowButton from 'components/buttons/ArrowButton';
import StateButton from 'components/buttons/StateButton';
import StateIcon from 'components/icons/StateIcon';
import AddedToBasket from 'components/notifications/AddedToBasket';
import Heading from 'components/text/Heading';
import Paragraph from 'components/text/Paragraph';

const Overlay = styled('div', {
    margin: '4px',
    borderRadius: '16px',
    background: 'var(--color-contrast-lowest)',
    padding: '16px',
    scrollbarWidth: 'none', // Hide scrollbar in firefox

    '::-webkit-scrollbar': {
        display: 'none', // Hide scrollbar in safari and chrome
    },

    [media.min['mobile.lg']]: {
        borderRadius: '8px',
        overflowY: 'scroll',
        padding: '16px 8px',
    },
});

const Header = styled('div', {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '16px',

    [media.min['mobile.lg']]: {
        padding: '0px 8px',
    },
});

const StateIcons = styled('div', {
    position: 'relative',
});

const CurrentProduct = styled('div', {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '16px',
});

const Variations = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    maxHeight: '300px',
    overflowY: 'scroll',
    marginBottom: '16px',
    '::-webkit-scrollbar': {
        display: 'none', // Hide scrollbar in safari and chrome
    },
    scrollbarWidth: 'none', // Hide scrollbar in firefox
});

const Footer = styled('div', {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: '8px',
});

const Description = styled('div', {
    width: '50%',
});

const QuickshopOverlay = ({
    $style = {},
    activePrice,
    addedToBasketAlgoliaData,
    addedToBasketData,
    color,
    isOpen,
    name,
    originalPrice,
    setButtonState = () => null,
    setIsOpen,
    uri,
    variations = [],
}) => {
    const path = useSelector(state => state.application.path);
    const dispatch = useTypedDispatch();
    const notifications = useNotifications();
    const [css] = useStyletron();
    const overlayRef = useRef();
    const { infoLabel, infoParagraph } = useProductCardQuery();
    const listener = useEventListener('window');
    const [addedToBasket, setAddedToBasket] = useState(false);
    const [selectedVariant, setSelectedVariant] = useState(null);

    // Close on click if element isn't button or overlay child.
    useEffect(() => {
        let handler = null;

        if (isOpen && setIsOpen) {
            handler = listener.subscribe('click', e => {
                const overlay = overlayRef.current;
                e.stopPropagation();

                if (overlay && e.target !== overlay && overlay.contains(e.target)) {
                    return;
                }

                setIsOpen(false);
            });
        }

        return () => handler && listener.unsubscribe('click', handler);
    }, [isOpen]);

    const handleClick = async (e, variant) => {
        e.preventDefault();
        setButtonState('loading');
        setSelectedVariant(variant);

        try {
            await dispatch(addBasketItem(variant.id, variant.trackingMeta));
            setButtonState('success');
            setAddedToBasket(true);

            notifications.push(AddedToBasket, {
                ...addedToBasketData,
                size: variations.filter(item => item.id === variant.id)?.[0]?.name || '',
            });

            if (addedToBasketAlgoliaData) {
                Events.trigger(SearchEvents.PRODUCT_ADDED, addedToBasketAlgoliaData);
            }
        } catch (error) {
            logger.error(error);
            setButtonState('error');
            setAddedToBasket(false);
        } finally {
            setTimeout(() => setButtonState('default'), 2800);
            setTimeout(() => setAddedToBasket(false), 2800);
            setTimeout(() => setSelectedVariant(null), 2800);
        }
    };

    const showPrice = activePrice && originalPrice;

    return (
        <Overlay $style={$style} ref={overlayRef}>
            <Header>
                <Heading as="h3" fontKeys="Primary/16_120_-1">
                    {fm('Add to bag')}
                </Heading>
                <StateIcons>
                    <button
                        className={css({
                            position: 'absolute',
                            right: 0,
                            display: ['flex', null, null, 'none'],
                        })}
                        type="button"
                        onClick={e => {
                            e.preventDefault();
                            overlay.close('quickshop');
                        }}
                    >
                        <StateIcon size="16px" state="close" />
                    </button>
                    <button
                        className={css({ display: ['none', null, null, 'flex'] })}
                        type="button"
                        onClick={e => {
                            e.preventDefault();
                            setIsOpen(false);
                        }}
                    >
                        <StateIcon size="16px" state="close" />
                    </button>
                </StateIcons>
            </Header>
            {name || showPrice ? (
                <CurrentProduct>
                    <div>
                        {name && (
                            <Heading $style={{ marginBottom: '4px' }} fontKeys="Secondary/14_130_500">
                                {name}
                            </Heading>
                        )}
                        {color && (
                            <Paragraph $style={{ color: 'var(--color-text-subtle)' }} fontKeys="Miscellaneous/12_100">
                                {color}
                            </Paragraph>
                        )}
                    </div>
                    {showPrice && (
                        <Price
                            $style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}
                            activePrice={activePrice}
                            fontStyling={textStyles['Miscellaneous/14_100']}
                            originalPrice={originalPrice}
                        />
                    )}
                </CurrentProduct>
            ) : null}
            <Variations scrolldirection="horizontal">
                {variations.map(variant => {
                    const [hover, setHover] = useState(false);

                    let message = '';
                    let extraText = null;
                    const noPrice = variant.trackingMeta?.priceAsNumber === undefined;

                    // @todo: get few left from api
                    if (variant.fewLeft) {
                        message = `${fm('Few left')}`;
                    } else if (!variant.inStock || noPrice) {
                        message = `${fm('Out of stock')}`;
                    }

                    const isSelected = variant.id === selectedVariant?.id;
                    const stateIcon = isSelected ? 'loading' : 'default';

                    if (hover && !(isSelected && addedToBasket)) {
                        extraText = fm('Add to cart');
                    } else if (isSelected && addedToBasket) {
                        extraText = fm('Added to cart');
                    }

                    return (
                        <StateButton
                            $style={{
                                textAlign: 'left',
                                display: 'flex',
                                justifyContent: 'space-between',
                                height: 'auto',
                                padding: ['12px 16px', null, null, '8px 12px'],
                                position: 'relative',

                                // Special styles for this button since it does not follow the regular theme
                                ':hover:not(:disabled):not(.disabled)': {
                                    color: 'var(--color-text-button-inverted)',
                                    background: 'var(--color-bg-button-inverted)',

                                    '::before': {
                                        position: 'absolute',
                                        right: '36px',
                                        top: '50%',
                                        transform: 'translateY(-50%)',
                                        ...textStyles['Miscellaneous/12_100'],
                                    },
                                },
                                ':focus:not(:disabled):not(.disabled)': {
                                    color: 'var(--color-text-button-inverted)',
                                    background: 'var(--color-bg-button-inverted)',
                                    '::before': {
                                        position: 'absolute',
                                        right: '36px',
                                        top: '50%',
                                        transform: 'translateY(-50%)',
                                        ...textStyles['Miscellaneous/12_100'],
                                    },
                                },
                                ':disabled': {
                                    textDecoration: 'line-through',
                                },
                            }}
                            active={isSelected && addedToBasket}
                            disabled={!variant.inStock || noPrice}
                            extraText={extraText}
                            fontKeys="Miscellaneous/14_100"
                            key={variant.name}
                            state={
                                isSelected && addedToBasket ? 'success' : variant.inStock || noPrice ? stateIcon : null
                            }
                            theme="default"
                            type="button"
                            onClick={
                                variant.inStock
                                    ? e => {
                                          handleClick(e, variant);
                                          setTimeout(() => {
                                              if (setIsOpen) {
                                                  setIsOpen(false);
                                              }
                                              overlay.close('quickshop');
                                          }, 2800);
                                      }
                                    : undefined
                            }
                            onMouseEnter={() => setHover(true)}
                            onMouseLeave={() => setHover(false)}
                        >
                            {variant.name}

                            <span
                                className={css({
                                    position: 'absolute',
                                    margin: '0 auto',
                                    right: '132px',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    ...textStyles['Miscellaneous/10_100'],
                                })}
                            >
                                {message}
                            </span>
                        </StateButton>
                    );
                })}
            </Variations>
            <Footer>
                <Description>
                    {infoLabel && (
                        <Heading as="h3" fontKeys="Secondary/14_130_500" $style={{ marginBottom: '4px' }}>
                            {infoLabel}
                        </Heading>
                    )}
                    {infoParagraph && (
                        <div className={css(textStyles['Secondary/12_130'])}>
                            <RichText data={infoParagraph} />
                        </div>
                    )}
                </Description>
                <ArrowButton
                    $style={{ alignSelf: 'flex-end' }}
                    as="a"
                    fontKeys="Miscellaneous/14_100"
                    size="sm"
                    theme="inverted"
                    href={`${path}${uri}`}
                >
                    {fm('Go to product')}
                </ArrowButton>
            </Footer>
        </Overlay>
    );
};

QuickshopOverlay.propTypes = {
    $style: PropTypes.object,
    activePrice: PropTypes.string,
    addedToBasketAlgoliaData: PropTypes.object,
    addedToBasketData: PropTypes.object,
    color: PropTypes.string,
    isOpen: PropTypes.bool,
    name: PropTypes.string,
    originalPrice: PropTypes.string,
    setButtonState: PropTypes.func,
    setIsOpen: PropTypes.func,
    uri: PropTypes.string,
    variations: PropTypes.array,
};

export default QuickshopOverlay;
